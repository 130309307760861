import React from 'react';
import { motion } from 'framer-motion';
import './Pricing.css';

const Pricing = () => {
    const cardVariants = {
        hidden: { opacity: 0, scale: 0.95, y: 50 },
        visible: { opacity: 1, scale: 1, y: 0 },
        exit: { opacity: 0, scale: 0.95, y: -50 }
    };

    const container = {
        hidden: {},
        visible: {
            transition: {
                staggerChildren: 0.3,
            }
        },
        exit: {
            transition: {
                staggerChildren: 0.2,
                staggerDirection: -1
            }
        }
    };

    return (
        <section id="pricing" className="pricing-section section mb-5">
            <h2 style={{ fontFamily: 'D-DIN Condensed, sans-serif' }} className="pricing-heading">PRICING</h2>
            <h1 style={{ fontFamily: 'D-DIN Condensed, sans-serif' }} className='text-center'>Select a Package</h1>
            <p style={{ fontFamily: 'D-DIN Exp, sans-serif' }} className='text-center'>
                Download, surf, and stream with confidence <br /> with Connekt Broadband
            </p>

            <motion.div className="pricing-cards"
                variants={container}
                initial="hidden"
                whileInView="visible"
                exit="exit">

                <motion.div className="pricing-card"
                    variants={cardVariants}
                    transition={{ duration: 0.5 }}>
                    <div
                        style={{ fontFamily: 'D-DIN Exp, sans-serif' }}
                        className='option-one d-flex align-items-center justify-content-center mb-2'>
                        <span className="types-of-options">Residential Standard</span>
                        <span className='ms-1 popular'>Popular</span>
                    </div>
                    <div className="d-flex align-items-center justify-content-center my-3">
                        <img className='speed' src="../images/Frame 1321316010.png" alt="Speed Icon" />
                    </div>
                    <div className='d-flex align-items-center justify-content-center'>
                        <img className='monthly' src="../images/Frame-1321316011.png" alt="Monthly Rate Icon" />
                    </div>
                    <ul className="features-list">
                        <li>
                            <img src="../images/tick-circle.png" alt="Check" />
                            <p>Unlimited 24/7/365 Data</p>
                        </li>
                        <li>
                            <img src="../images/tick-circle.png" alt="Check" />
                            <p>VOIP Telephony Service</p>
                        </li>
                        <li>
                            <img src="../images/tick-circle.png" alt="Check" />
                            <p>24/7 Support</p>
                        </li>
                        <li>
                            <img src="../images/tick-circle.png" alt="Check" />
                            <p>Suitable for Personal Use</p>
                        </li>
                    </ul>
                    <button className='first-button' style={{ fontFamily: 'D-DIN Exp, sans-serif', marginTop: "5pc"}}>Order Now</button>
                </motion.div>

                <motion.div className="pricing-card"
                    variants={cardVariants}
                    transition={{ duration: 0.5 }}>
                    <div
                        style={{ fontFamily: 'D-DIN Exp, sans-serif' }}
                        className='d-flex align-items-center justify-content-center'>
                        <span className="types-of-options">Residential Premium</span>
                    </div>
                    <div className="d-flex align-items-center justify-content-center my-3">
                        <img className='speed' src="../images/Frame-1321316012.png" alt="Higher Speed Icon" />
                    </div>
                    <div className='d-flex align-items-center justify-content-center'>
                        <img className='monthly' src="../images/Frame 1321316012.png" alt="Higher Monthly Rate Icon" />
                    </div>
                    <ul className="features-list">
                        <li>
                            <img src="../images/tick-circle.png" alt="Check" />
                            <p>Unlimited 24/7/365 Data</p>
                        </li>
                        <li>
                            <img src="../images/tick-circle.png" alt="Check" />
                            <p>Free PBX Telephony</p>
                        </li>
                        <li>
                            <img src="../images/tick-circle.png" alt="Check" />
                            <p>Online TV Streaming</p>
                        </li>
                        <li>
                            <img src="../images/tick-circle.png" alt="Check" />
                            <p>24/7 Support</p>
                        </li>
                        <li>
                            <img src="../images/tick-circle.png" alt="Check" />
                            <p>Suitable for Home Use</p>
                        </li>
                    </ul>
                    <button className='second-button' style={{ fontFamily: 'D-DIN Exp, sans-serif' }}>Order Now</button>
                </motion.div>

                <motion.div className="pricing-card"
                    variants={cardVariants}
                    transition={{ duration: 0.5 }}>
                    <div
                        style={{ fontFamily: 'D-DIN Exp, sans-serif' }}
                        className='d-flex align-items-center justify-content-center'>
                        <span className="types-of-options">Corporate Premium</span>
                    </div>
                    <div className="d-flex align-items-center justify-content-center my-3">
                        <img className='speed' src="../images/Frame-1321316012.png" alt="Corporate Speed Icon" />
                    </div>
                    <div className='d-flex align-items-center justify-content-center'>
                        <img className='monthly' src="../images/Frame 1321316012 (1).png" alt="Corporate Monthly Rate Icon" />
                    </div>
                    <ul className="features-list">
                        <li>
                            <img src="../images/tick-circle.png" alt="Check" />
                            <p>Unlimited 24/7/365 Data</p>
                        </li>
                        <li>
                            <img src="../images/tick-circle.png" alt="Check" />
                            <p>Free PBX Telephony</p>
                        </li>
                        <li>
                            <img src="../images/tick-circle.png" alt="Check" />
                            <p>Online TV Streaming</p>
                        </li>
                        <li>
                            <img src="../images/tick-circle.png" alt="Check" />
                            <p>24/7 Support</p>
                        </li>
                        <li>
                            <img src="../images/tick-circle.png" alt="Check" />
                            <p>Suitable for Home Use</p>
                        </li>
                    </ul>
                    <button className='last-button' style={{ fontFamily: 'D-DIN Exp, sans-serif' }}>Order Now</button>
                </motion.div>

            </motion.div>
        </section>
    );
};

export default Pricing;
