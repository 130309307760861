import React from 'react';
import { motion } from 'framer-motion';
import './aboutUs2.css';

const AboutUs2 = () => {
    
    const imageVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                duration: 1.5
            }
        }
    };

    const textVariants = {
        hidden: { y: 50, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: {
                duration: 1.2,
                delay: 0.3 
            }
        }
    };

    return (
        <section className="about-us section mt-5">
            <motion.div 
                className="d-flex align-items-center justify-content-center"
                variants={imageVariants}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: false }}>
                <img className='img-fluid main-img' src="../images/Frame-1321315998.png" alt="" />
            </motion.div>
            
            <div className='description'>
                <motion.img 
                    className='mt-5 img-fluid responsive-img' 
                    src="../images/image.jpeg" 
                    alt=""
                    variants={imageVariants}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: false }} />

                <motion.div 
                    className="right-text mt-5"
                    variants={textVariants}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: false }}>
                    <span style={{ fontFamily: 'D-DIN Condensed, sans-serif' }} className="about-us mb-5">ABOUT US</span>
                    <h1 style={{ fontFamily: 'D-DIN Condensed, sans-serif'}}>No. 1 Internet Service Provider</h1>
                    <h1 style={{ fontFamily: 'D-DIN Condensed, sans-serif' }} className='mb-4'>Company In The Country</h1>
                    <p style={{ fontFamily: 'D-DIN Exp, sans-serif' }}>Connekt Broadband, has been providing internet and telecom services for almost a decade! We are committed to providing unlimited internet that is available anytime, everytime for home, business and office purposes. Stay constantly connected to the people that matter, with Connekt.</p>
                    <button style={{ fontFamily: 'DIN-Regular, sans-serif' }}  className='mt-3'>Learn More</button>
                </motion.div>
            </div>
        </section>
    );
};

export default AboutUs2;
