import React, { Component } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import './Contact.css';

class Contact extends Component {
    state = {
        formData: {
            service: '',
            address: '',
            first_name: '',
            last_name: '',
            phone: '',
            email: '',
            to_email: 'support@connekt.me',
            email_template: 'template1',
            message: ''
        },
        errors: {},
        formSubmitted: false,
        loading: false,
    };

    handleInputChange = (e) => {
        const { id, value } = e.target;
        this.setState(prevState => ({
            formData: {
                ...prevState.formData,
                [id]: value
            }
        }));
    };

    validate = () => {
        const errors = {};
        const { formData } = this.state;
        
        if (formData.service.trim() === "") 
            errors.service = "Service is required.";

        if (formData.address.trim() === "")
            errors.address = "Address is required.";

        if (formData.first_name.trim() === "")
            errors.first_name = "First Name is required";

        if (formData.last_name.trim() === "")
            errors.last_name = "Last Name is required";

        if (formData.email.trim() === "") {
            errors.email = "Email is required.";
        } else if (!this.isValidEmail(formData.email)) {
            errors.email = "Invalid email format.";
        }

        if (formData.phone.trim() === "")
            errors.phone = "Phone number is required";

        if (formData.to_email.trim() === "")
            errors.to_email = "Recipient Email is required";
        else if (!this.isValidEmail(formData.to_email)) {
            errors.to_email = "Invalid recipient email format.";
        }
        
        if (formData.message.trim() === "")
            errors.message = "Message is required";

        return Object.keys(errors).length === 0 ? null : errors;
    };

    isValidEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        const errors = this.validate();
        if(errors){
            this.setState({ errors }); 
            return;
        }
        const { formData } = this.state;
        
        try {
            this.setState({ loading: true });
            const response = await fetch('https://email-backend.nativetalkapp.com/flsk/connekt-mail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': '919c02f004339822ecb967cc526977b528f7ba55'
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                this.setState({
                    formData: {
                        service: '',
                        address: '',
                        first_name: '',
                        last_name: '',
                        phone: '',
                        email: '',
                        to_email: 'support@connekt.me',
                        email_template: "template1", 
                        message: ''
                    },
                    formSubmitted: true,
                    loading: false
                });
            } else {
                throw new Error('Failed to submit form');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    render() {
        const { formData, errors, formSubmitted, loading } = this.state;

        return (
            <section style={{ fontFamily: 'D-DIN Exp, sans-serif' }} className="form-section mt-5">
                <div className='contact-wrapper'>
                    <p>TALK TO US TODAY</p>
                    <h1 style={{letterSpacing:"-3px", marginTop:"-10px"}}>Connekt Now</h1>

                    <div className='contact-form'>
                        <Form onSubmit={this.handleSubmit}>
                            <Row className="mb-3">
                                <Col md={6}>
                                    <Form.Group controlId="service">
                                        <Form.Label>Select Service</Form.Label>
                                        <Form.Select aria-label="Select Service" value={formData.service} onChange={this.handleInputChange}>
                                            <option>Select Service</option>
                                            <option value="Connekt Lite">Connekt Lite</option>
                                            <option value="Connekt Pro">Connekt Pro</option>
                                            <option value="Connekt Premium">Connekt Premium</option>
                                        </Form.Select>
                                        {errors.service && <div className="alert alert-danger">{errors.service}</div>}
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="address">
                                        <Form.Label>Address</Form.Label>
                                        <Form.Control type="text" placeholder="Enter your address" onChange={this.handleInputChange} value={formData.address} />
                                        {errors.address && <div className="alert alert-danger">{errors.address}</div>}
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col md={6}>
                                    <Form.Group controlId="first_name">
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control type="text" placeholder="Enter your first name" onChange={this.handleInputChange} value={formData.first_name} />
                                        {errors.first_name && <div className="alert alert-danger">{errors.first_name}</div>}
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="last_name">
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control type="text" placeholder="Enter your last name" onChange={this.handleInputChange} value={formData.last_name} />
                                        {errors.last_name && <div className="alert alert-danger">{errors.last_name}</div>}
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col md={6}>
                                    <Form.Group controlId="phone">
                                        <Form.Label>Phone Number</Form.Label>
                                        <Form.Control type="tel" placeholder="Enter your phone number" onChange={this.handleInputChange} value={formData.phone} />
                                        {errors.phone && <div className="alert alert-danger">{errors.phone}</div>}
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="email">
                                        <Form.Label>Email Address</Form.Label>
                                        <Form.Control type="email" placeholder="Enter your email address" onChange={this.handleInputChange} value={formData.email} />
                                        {errors.email && <div className="alert alert-danger">{errors.email}</div>}
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col md={12}>
                                    <Form.Group controlId="message">
                                        <Form.Label>Message</Form.Label>
                                        <Form.Control as="textarea" rows={6} placeholder="Your message" onChange={this.handleInputChange} value={formData.message} />
                                        {errors.message && <div className="alert alert-danger">{errors.message}</div>}
                                    </Form.Group>
                                </Col>
                            </Row>

                            {formSubmitted && <div className="alert alert-success">Thank you! Your message has been sent.</div>}

                            <div className="text-md-start">
                                <button type="submit" disabled={loading}>
                                    {loading ? <div className="spinner-border text-light" role="status"><span className="visually-hidden">Loading...</span></div> : "Submit"}
                                </button>
                            </div>
                        </Form>
                    </div>
                </div>
            </section>
        );
    }
}

export default Contact;
