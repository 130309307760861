import React from 'react';
import { Link as ScrollLink } from 'react-scroll';
import './navbar.css';

const CustomNavbar = () => {
    return (
        <nav style={{ fontFamily: 'DIN-Regular, sans-serif' }} className={`custom-navbar navbar navbar-expand-lg navbar-light bg-light pt-4`}>
            <div className="container">
                <a className="navbar-brand" href="/">
                    <img src="../images/connekt-logo-1.png" height="30" alt="Logo" />
                </a>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-end text-center" id="navbarNav">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                        <a className="nav-link" href="/">HOME</a>
                        </li>
                        <li className="nav-item">
                            <ScrollLink className="nav-link" to="features" smooth={true} duration={500}>FEATURES</ScrollLink>
                        </li>
                        <li className="nav-item">
                            <ScrollLink className="nav-link" to="pricing" smooth={true} duration={500}>PRICING</ScrollLink>
                        </li>
                        <li className="nav-item">
                            <ScrollLink className="nav-link" to="process" smooth={true} duration={500}>PROCESS</ScrollLink>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/about">ABOUT US</a>
                        </li>
                        <li className="nav-item">
                            <ScrollLink className="nav-link" to="contact" smooth={true} duration={500}>CONTACT</ScrollLink>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default CustomNavbar;
