import React from 'react';
import { motion } from 'framer-motion';
import './Process.css';

const Process = () => {
    const cardVariants = {
        hidden: { rotateY: 90, opacity: 0 },
        visible: {
            rotateY: 0,
            opacity: 1,
            transition: {
                rotateY: {
                    type: "spring",
                    stiffness: 50,
                    damping: 20,
                    duration: 0.5
                },
                opacity: {
                    duration: 0.5
                }
            }
        }
    };

    return (
        <section className="process-section section" id="process">
            <h2 className="process-heading text-center">PROCESS</h2>
            <h1
                style={{ fontFamily: 'D-DIN Condensed, sans-serif', letterSpacing: "-3px" }}
                className='text-center fw-bold'>
                Fast Unlimited Internet <br /> in 3 Steps
            </h1>

            <div style={{ fontFamily: 'D-DIN Exp, sans-serif' }} className="process-cards">
                <motion.div className="process-card"
                    variants={cardVariants}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: false, amount: 0.5 }}>
                    <div className="process-circle">
                        <img src="../images/icon.png" alt="" />
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                        <span>INSPECT</span>
                    </div>
                    <p className='text-center'>Our support installer inspects your premises prior to installation</p>
                </motion.div>

                <motion.div className="process-card middle"
                    variants={cardVariants}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: false, amount: 0.5 }}>
                    <div className="process-circle middle">
                        <img src="../images/Group.png" alt="" />
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                        <span>INSTALL</span>
                    </div>
                    <p className='text-center'>Your antenna is fine-tuned and set to ensure the strongest signal</p>
                </motion.div>

                <motion.div className="process-card"
                    variants={cardVariants}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: false, amount: 0.5 }}>
                    <div className="process-circle">
                        <img src="../images/material-symbols_frame-inspect.png" alt="" />
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                        <span>CONNECT</span>
                    </div>
                    <p className='text-center'>Connect all your devices to your wifi gateway and you are ready to go</p>
                </motion.div>
            </div>
        </section>
    );
};

export default Process;
