import React from 'react';
import './features.css';

const Features = () => {
    return (
        <section id="features" className="features section">
            <img className='img-fluid' src={'../images/Frame-1321315999.png'} alt="" />
        </section>
    );
};

export default Features;
